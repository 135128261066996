(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

document.addEventListener("DOMContentLoaded", function () {
  // Don't touch
  // Don't touch
  objectFitImages();
  svg4everybody();
  // End don't touch

  require('./modules/target-blank.js');
  require('./modules/sliders');
  require('./modules/menu');
  require('./modules/animation');
  require('./modules/tabs');
});

// remove preloader
window.addEventListener('load', function () {
  document.querySelectorAll('a[href]').forEach(function (a) {
    a.addEventListener('click', function (e) {
      scrollToHash(getSamePageAnchor(a), e);
    });
  });
  scrollToHash(window.location.hash);
});
function scrollToHash(hash, e) {
  var elem = hash ? document.querySelector(hash) : false;
  if (elem) {
    if (e) e.preventDefault();
    gsap.to(window, {
      scrollTo: {
        y: elem,
        offsetY: 50
      }
    });
  }
}
function getSamePageAnchor(link) {
  if (link.protocol !== window.location.protocol || link.host !== window.location.host || link.pathname !== window.location.pathname || link.search !== window.location.search) {
    return false;
  }
  return link.hash;
}

},{"./modules/animation":2,"./modules/menu":3,"./modules/sliders":4,"./modules/tabs":5,"./modules/target-blank.js":6}],2:[function(require,module,exports){
"use strict";

(function () {
  gsap.registerPlugin(ScrollTrigger, ScrollSmoother, SplitText, MorphSVGPlugin);
  ScrollSmoother.create({
    smooth: 1,
    // how long (in seconds) it takes to "catch up" to the native scroll position
    effects: true,
    // looks for data-speed and data-lag attributes on elements
    smoothTouch: 0.1 // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
  });
  gsap.utils.toArray('.animate-word').forEach(function (el) {
    ScrollTrigger.create({
      trigger: el,
      start: 'top bottom',
      onEnter: function onEnter() {
        var mySplitText = new SplitText(el, {
          type: "words"
        });
        var splitTextTimeline = gsap.timeline();
        splitTextTimeline.from(mySplitText.words, {
          duration: 1,
          opacity: 0,
          y: 100,
          stagger: 0.1
        });
      }
    });
  });
  gsap.utils.toArray('.animate').forEach(function (el) {
    ScrollTrigger.create({
      trigger: el,
      start: 'top 80%',
      onEnter: function onEnter() {
        return el.classList.add('animated');
      }
    });
  });
  gsap.utils.toArray('.waved-text.coctails').forEach(function (el) {
    ScrollTrigger.create({
      trigger: el,
      start: 'top 80%',
      onEnter: function onEnter() {
        return el.classList.add('active');
      }
    });
  });
  gsap.utils.toArray('.menu-item').forEach(function (el) {
    ScrollTrigger.create({
      trigger: el,
      start: 'top bottom',
      onEnter: function onEnter() {
        var t1 = gsap.timeline();
        t1.from(el, {
          duration: 1,
          y: 100,
          opacity: 0
        });
      }
    });
  });
  gsap.utils.toArray('.arrow-link').forEach(function (el) {
    var start = el.querySelector('svg #start');
    var end = el.querySelector('svg #end');
    el.addEventListener('mouseenter', function (e) {
      console.log('mouseenter');
      gsap.to(start, {
        duration: 0.2,
        morphSVG: end,
        ease: "power1"
      });
    });
    el.addEventListener('mouseleave', function () {
      console.log('mouseleave');
      gsap.to(start, {
        duration: 0.2,
        morphSVG: start,
        ease: "power1"
      });
    });
  });
})();

},{}],3:[function(require,module,exports){
"use strict";

(function () {
  var $header = document.querySelector('.header');
  var $menu = document.querySelector('.main-nav');
  var $hamburger = document.querySelector('.burger');
  var isMenuOpened = false;
  $hamburger.addEventListener('click', function () {
    this.classList.toggle('opened');
    $menu.classList.toggle('opened');
    $header.classList.toggle('menu-opened');
    isMenuOpened = !isMenuOpened;
    if (isMenuOpened) {
      bodyScrollLock.disableBodyScroll($menu);
    } else {
      bodyScrollLock.clearAllBodyScrollLocks();
    }
  });
  var menuItems = $menu.querySelectorAll('a');
  menuItems.forEach(function (item) {
    item.addEventListener('click', function () {
      if (window.innerWidth < 1025) {
        $hamburger.classList.remove('opened');
        $menu.classList.remove('opened');
        $header.classList.remove('menu-opened');
      }
    });
  });
  window.addEventListener('resize', debounce(function () {
    if (isMenuOpened && window.innerWidth >= 1025) {
      bodyScrollLock.clearAllBodyScrollLocks();
    }
  }));
  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function later() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }
})();

},{}],4:[function(require,module,exports){
"use strict";

(function () {
  $('.location-slider').slick({
    slidesToShow: 1,
    arrows: true,
    infinite: true
  });
})();

},{}],5:[function(require,module,exports){
"use strict";

(function () {
  var container = document.querySelector('.tab-container');
  if (container) {
    var activate = function activate(tabs, index) {
      tabs.forEach(function (tab, i) {
        if (i != index) {
          tab.classList.remove('active');
        } else {
          tab.classList.add('active');
        }
      });
    };
    var tabs = container.querySelectorAll('.tab');
    var panes = container.querySelectorAll('.tab-pane');
    var text = container.querySelectorAll('.waved-text');
    tabs.forEach(function (tab, i) {
      tab.addEventListener('click', function (e) {
        activate(tabs, i);
        activate(panes, i);
        activate(text, i);
      });
    });
  }
})();

},{}],6:[function(require,module,exports){
"use strict";

function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t["return"] || t["return"](); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
// add target blank to external links
var siteUrl = window.location.hostname;
var links = document.querySelectorAll('a[href*="//"]:not([href*="' + siteUrl + '"])');
var _iterator = _createForOfIteratorHelper(links),
  _step;
try {
  for (_iterator.s(); !(_step = _iterator.n()).done;) {
    var elem = _step.value;
    elem.setAttribute('target', '_blank');
    elem.setAttribute('rel', 'noopener noreferrer');
  }
} catch (err) {
  _iterator.e(err);
} finally {
  _iterator.f();
}

},{}]},{},[1]);
